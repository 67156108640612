import React from "react";
import Base from "../../layouts/Base";
import { Link, useLocation } from 'react-router-dom';

const BatteryBase = ({ children, title, totalVoltage, currentAmpere }) => {
    const location = useLocation();


    return (
        <Base>
            <div className="my-3">
                <h2 className="animate-fade-in text-xl uppercase font-semibold">{title}</h2>
            </div>
            <div>
                <div className="w-full lg:flex justify-between">
                    <div className="rounded-lg border border-gray-300 bg-white py-2 px-3 my-1">
                        <nav className="flex flex-wrap gap-4">
                            <div className="whitespace-nowrap inline-flex rounded-lg py-2 px-3 text-xl font-medium text-gray-600 transition-all duration-200 ease-in-out ">
                                SRTING 2
                            </div>
                            <div className="whitespace-nowrap inline-flex rounded-lg bg-neutron-primary/80 py-2 px-3 font-medium text-white text-xl uppercase transition-all duration-200 ease-in-out "> Charging </div>
                            <div className="whitespace-nowrap inline-flex rounded-lg bg-neutron-primary/80 py-2 px-3 font-medium text-white text-xl uppercase transition-all duration-200 ease-in-out"> {totalVoltage} Vdc </div>
                            <div className="whitespace-nowrap inline-flex rounded-lg bg-neutron-primary/80 py-2 px-3 font-medium text-white text-xl uppercase transition-all duration-200 ease-in-out "> {currentAmpere} A </div>
                        </nav>
                    </div>
                    <div className="rounded-lg border border-gray-300 bg-white py-2 px-3 my-1">
                        <nav className="flex flex-wrap gap-4">
                            <Link to={`/batterysystem`} className={`whitespace-nowrap inline-flex rounded-lg py-2 px-3 text-xl font-medium  transition-all duration-200 ease-in-out  ${location.pathname === '/batterysystem' ? 'bg-neutron-primary/80 text-white' : 'text-gray-800 hover:bg-neutron-primary/80 hover:text-white'}`}> Overview </Link>
                            {/* <Link to={`/batterysystem/chart`} className={`whitespace-nowrap inline-flex rounded-lg py-2 px-3 text-xl font-medium  transition-all duration-200 ease-in-out  ${location.pathname === '/batterysystem/chart' ? 'bg-neutron-primary/80 text-white' : 'text-gray-800 hover:bg-neutron-primary/80 hover:text-white'}`}> Bar Chart </Link> */}
                            <Link to={`/batterysystem`} className={`whitespace-nowrap inline-flex rounded-lg py-2 px-3 text-xl font-medium  transition-all duration-200 ease-in-out  ${location.pathname === '/batterysystem/chart' ? 'bg-neutron-primary/80 text-white' : 'text-gray-800 hover:bg-neutron-primary/80 hover:text-white'}`}> Bar Chart </Link>
                        </nav>
                    </div>
                </div>
            </div>
            <div className={`animate-slide-in-up p-4 bg-white rounded-md shadow-md my-5 col-span-12 lg:col-span-10`}>
                {children}
            </div>
        </Base>
    );
}

export default BatteryBase;
