import React from 'react';
import Base from '../../layouts/Base';

const About = () => {
    return (
        <Base>
            <>
                halo about
            </>
        </Base>
    );
};

export default About;