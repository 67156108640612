import React from "react";
import { PiCarBatteryDuotone } from "react-icons/pi";
import { GiFuelTank } from "react-icons/gi";

const StatsHome = () => {
    return (
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-6">

<div class="flex items-center p-4 bg-white rounded-md shadow-md">
                <div class="flex flex-shrink-0 items-center justify-center bg-green-200 h-16 w-16 rounded">
                    <PiCarBatteryDuotone class="w-12 h-12 fill-current text-green-700" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                    <span class="text-xl font-bold">25</span>
                    <div class="flex items-center justify-between">
                        <span class="text-gray-500">Battery</span>
                    </div>
                </div>
            </div>

            <div class="flex items-center p-4 bg-white rounded-md shadow-md">
                <div class="flex flex-shrink-0 items-center justify-center bg-green-200 h-16 w-16 rounded">
                    <PiCarBatteryDuotone class="w-12 h-12 fill-current text-green-700" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                    <span class="text-xl font-bold">25</span>
                    <div class="flex items-center justify-between">
                        <span class="text-gray-500">Battery</span>
                    </div>
                </div>
            </div>

            <div class="flex items-center p-4 bg-white rounded-md shadow-md">
                <div class="flex flex-shrink-0 items-center justify-center bg-green-200 h-16 w-16 rounded">
                    <PiCarBatteryDuotone class="w-12 h-12 fill-current text-green-700" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                    <span class="text-xl font-bold">25</span>
                    <div class="flex items-center justify-between">
                        <span class="text-gray-500">Battery</span>
                    </div>
                </div>
            </div>

            <div class="flex items-center p-4 bg-white rounded-md shadow-md">
                <div class="flex flex-shrink-0 items-center justify-center bg-orange-200 h-16 w-16 rounded">
                    <GiFuelTank class="w-12 h-12 fill-current text-orange-600" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                    <span class="text-xl font-bold">25%</span>
                    <div class="flex items-center justify-between">
                        <span class="text-gray-500">Fuel</span>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default StatsHome;