import React from 'react';

const CleanLayout = ({ children }) => {
  return (
    <div className="mt-12 flex flex-col items-center">
      {children}
    </div>
  );
};

export default CleanLayout;
