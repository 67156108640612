import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { login } from '../../services/authService';
import Auth from '../../layouts/Auth';
import { TbLogin2, TbUserScan, TbEye, TbEyeClosed } from "react-icons/tb";
import Swal from 'sweetalert2';
import { GoMail } from 'react-icons/go';
import logo from '../../assets/images/favicon.webp';

const Login = () => {
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error] = useState(null);
  const navigate = useNavigate();
  const passwordInputRef = useRef(null); // Create a ref for the password input

  const handleLogin = async (e) => {
    e.preventDefault();

    // Show loading SweetAlert
    Swal.fire({
      title: 'Logging in...',
      text: 'Please wait while we process your login.',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      await login(emailOrUsername, password);

      // Close the loading SweetAlert
      Swal.close();

      // Redirect to the home page
      navigate('/');
    } catch (err) {
      Swal.close();
      Swal.fire({
        title: 'Login Failed',
        text: err.error || 'An error occurred during login.',
        icon: 'error',
        confirmButtonColor: '#009e4d',
        confirmButtonText: 'Try Again'
      });

      // Clear the password field and focus on it
      setPassword('');
      if (passwordInputRef.current) {
        passwordInputRef.current.focus();
      }
    }
  };

  const handleGoogleLogin = () => {
    // Menampilkan SweetAlert loading
    Swal.fire({
      title: 'Logging in...',
      text: 'Please wait while we process your login.',
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    // URL untuk login Google
    const googleLoginUrl = 'https://backenddemo.iotneutron.com/api/auth/google'; // Ganti dengan URL backend Anda

    // Mengarahkan pengguna ke URL login Google
    window.location.href = googleLoginUrl;
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <Auth>
      <div className="mx-auto text-center mb-5">
        <img src={logo} alt="Neutron Logo" className="mx-auto mb-3" />
        <h2 className="text-xl text-neutron-primary md:text-2xl font-extrabold text-space-grotesk">
          Welcome to Scada by Neutron
        </h2>
      </div>
      <form onSubmit={handleLogin} className="w-full flex-1 mt-8">
        <div className="mx-auto w-full">
          <div className="my-3">
            <label htmlFor="emailOrUsername" className="mb-2 block text-sm font-medium">Username/Email</label>
            <div className="relative">
              <input
                type="text"
                id="emailOrUsername"
                name="emailOrUsername"
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                className="block w-full rounded-md border border-gray-200 py-3 px-4 pr-11 text-sm shadow-sm outline-none focus:z-10 focus:border-neutron-primary focus:ring-neutron-primary"
                placeholder="Username..."
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 z-20 flex items-center pr-4">
                <TbUserScan className="h-6 w-6 text-gray-400" />
              </div>
            </div>
          </div>

          <div className="my-3">
            <label htmlFor="password" className="mb-2 block text-sm font-medium">Password</label>
            <div className="relative">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border border-gray-200 py-3 px-4 pr-11 text-sm shadow-sm outline-none focus:z-10 focus:border-neutron-primary focus:ring-neutron-primary"
                placeholder="Password"
                ref={passwordInputRef} // Attach ref to the input
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="block w-full rounded-md border-transparent focus:border-blue-600 focus:ring-blue-600"
                >
                  {passwordVisible ? <TbEyeClosed className="w-6 h-6" /> : <TbEye className="w-6 h-6" />}
                </button>
              </div>
            </div>
          </div>

          {error && <p className="text-red-500">{error}</p>}

          <button
            type="submit"
            className="mt-5 tracking-wide font-semibold bg-neutron-primary/90 text-gray-100 w-full py-4 rounded-lg hover:bg-neutron-primary transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
            <TbLogin2 className="w-6 h-6 -ml-2" />
            <span className="ml-3">
              Sign In
            </span>
          </button>
          <div className="mt-3">
            <button
              type="button" onClick={handleGoogleLogin}
              className="mt-5 tracking-wide font-semibold bg-red-600 text-gray-100 w-full py-2 rounded-lg hover:bg-red-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none">
              <GoMail className="w-6 h-6 -ml-2" />
              <span className="ml-3">
                Login With Gmail
              </span>
            </button>
          </div>
          <p className="mt-6 text-xs text-gray-600 text-center w-full">
            Please register if you don't have an account,
            <Link to={`/register`} className="border-b border-gray-500 border-dotted mx-2 font-semibold">
              visit this page.
            </Link>
          </p>
        </div>
      </form>
    </Auth>
  );
};

export default Login;
