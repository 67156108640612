// App.js
import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import NoInternet from './pages/NoInternet/Index';
import Home from './pages/Home/Index';
import About from './pages/About/Index';
import Login from './pages/Login/Index';
import Register from './pages/Register/Index';
import Verification from './pages/Verification/Index';
import NotFound from './pages/NotFound/Index';
import Powersystem from './pages/PowerSystem/Index';
import BatterySystem from './pages/BatterySystem/BatteryIndex';
import BatteryChart from './pages/BatterySystem/BatteryChart';
import FuelSystem from './pages/FuelSystem/Index';
import EventAlarm from './pages/EventAlarm/Index';
import RealtimeMonitoringExternal from './pages/RealtimeMonitoringExternal/Index';
import RealtimeMonitoringInternal from './pages/RealtimeMonitoringInternal/Index';
import { ProtectedRoute, GuestRoute } from './middleware/authMiddleware';


function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const accessToken = urlParams.get('accessToken');
    const refreshToken = urlParams.get('refreshToken');
    const user = urlParams.get('user');

    if (accessToken && refreshToken) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      // Uraikan user dari string JSON
      if (user) {
        const userData = JSON.parse(decodeURIComponent(user)); // Uraikan dan decode
        localStorage.setItem('userData', JSON.stringify({
          id: userData.id,
          email: userData.email,
          name: userData.name,
          username: userData.username
        }));
        localStorage.setItem('userMenus', JSON.stringify(userData.menus));
      }

      // Menghapus query parameters dari URL
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [location]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOnline) {
    return <NoInternet />;
  }
  
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
      <Route path="/about" element={<ProtectedRoute><About /></ProtectedRoute>} />
      <Route path="/powersystem" element={<ProtectedRoute><Powersystem /></ProtectedRoute>} />
      <Route path="/batterysystem" element={<ProtectedRoute><BatterySystem /></ProtectedRoute>} />
      <Route path="/batterysystem/chart" element={<ProtectedRoute><BatteryChart /></ProtectedRoute>} />
      <Route path="/fuelsystem" element={<ProtectedRoute><FuelSystem /></ProtectedRoute>} />
      <Route path="/eventalarm" element={<ProtectedRoute><EventAlarm /></ProtectedRoute>} />
      <Route path="/realtimeinternal" element={<ProtectedRoute><RealtimeMonitoringInternal /></ProtectedRoute>} />
      <Route path="/realtimeexternal" element={<ProtectedRoute><RealtimeMonitoringExternal /></ProtectedRoute>} />
      <Route path="/login" element={<GuestRoute><Login /></GuestRoute>} />
      <Route path="/verify-email" element={<GuestRoute><Verification /></GuestRoute>} />
      <Route path="/register" element={<GuestRoute><Register /></GuestRoute>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;