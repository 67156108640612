import React from 'react';

const TableEventAlarm = () => {

    const tableData = [
        {
            date: "2024-09-25",
            time: "10:00 AM",
            equipment: "Generator",
            data: "Voltage",
            value: 220,
            unit: "V",
            thresholdUp: 240,
            thresholdDown: 200,
            status: "Normal",
            level: "OK",
            remark: "No issues"
        },
        {
            date: "2024-09-25",
            time: "10:30 AM",
            equipment: "Pump",
            data: "Flow Rate",
            value: 50,
            unit: "L/min",
            thresholdUp: 60,
            thresholdDown: 40,
            status: "Warning",
            level: "Low",
            remark: "Check flow"
        },
        {
            date: "2024-09-25",
            time: "11:00 AM",
            equipment: "Compressor",
            data: "Pressure",
            value: 90,
            unit: "psi",
            thresholdUp: 100,
            thresholdDown: 80,
            status: "Normal",
            level: "OK",
            remark: "All good"
        },
        {
            date: "2024-09-25",
            time: "11:30 AM",
            equipment: "Boiler",
            data: "Temperature",
            value: 180,
            unit: "°F",
            thresholdUp: 200,
            thresholdDown: 150,
            status: "Normal",
            level: "OK",
            remark: "Stable"
        },
        {
            date: "2024-09-25",
            time: "12:00 PM",
            equipment: "Chiller",
            data: "Cooling Load",
            value: 75,
            unit: "kW",
            thresholdUp: 80,
            thresholdDown: 60,
            status: "Normal",
            level: "OK",
            remark: "Operating normally"
        },
        {
            date: "2024-09-25",
            time: "12:30 PM",
            equipment: "Fan",
            data: "Speed",
            value: 1500,
            unit: "RPM",
            thresholdUp: 1600,
            thresholdDown: 1400,
            status: "Normal",
            level: "OK",
            remark: "Running well"
        },
        {
            date: "2024-09-25",
            time: "01:00 PM",
            equipment: "Valve",
            data: "Opening Percentage",
            value: 70,
            unit: "%",
            thresholdUp: 80,
            thresholdDown: 60,
            status: "Normal",
            level: "OK",
            remark: "No issues"
        },
        {
            date: "2024-09-25",
            time: "01:30 PM",
            equipment: "Sensor",
            data: "Humidity",
            value: 40,
            unit: "%",
            thresholdUp: 50,
            thresholdDown: 30,
            status: "Normal",
            level: "OK",
            remark: "Within range"
        },
        {
            date: "2024-09-25",
            time: "02:00 PM",
            equipment: "Transformer",
            data: "Load",
            value: 200,
            unit: "kVA",
            thresholdUp: 220,
            thresholdDown: 180,
            status: "Normal",
            level: "OK",
            remark: "All good"
        },
        {
            date: "2024-09-25",
            time: "02:30 PM",
            equipment: "Air Conditioner",
            data: "Cooling Capacity",
            value: 5,
            unit: "ton",
            thresholdUp: 6,
            thresholdDown: 4,
            status: "Warning",
            level: "Low",
            remark: "Check cooling"
        },
    ];

    return (
        <div>
            <div className="flex flex-col mt-6">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                <thead className="bg-gray-50 dark:bg-gray-800">
                                    <tr className="text-center text-lg">
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" width="100" rowSpan="2">Date</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" width="80" rowSpan="2">Time</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" rowSpan="2">Equipment</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" rowSpan="2">Data</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" rowSpan="2">Value</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" rowSpan="2">Unit</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" colSpan="2">Threshold</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" width="120" rowSpan="2">Status</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" width="120" rowSpan="2">Level</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400" rowSpan="2">Remark</th>
                                    </tr>
                                    <tr className="text-center text-lg">
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400">Up</th>
                                        <th className="py-3.5 px-4 text-sm font-semibold text-center text-gray-500 dark:text-gray-400">Down</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                    {tableData.map((row, index) => (
                                        <tr key={index} className="text-center hover:bg-gray-100">
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.date}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.time}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.equipment}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.data}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.value}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.unit}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.thresholdUp}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.thresholdDown}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.status}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.level}</td>
                                            <td className="py-3 px-4 text-sm text-gray-500 dark:text-gray-400">{row.remark}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TableEventAlarm;