import React, { useState, useEffect } from "react";
import BatteryIcon from '../../assets/images/battery.png';
import BatteryBase from "./BatteryBase";
import axiosInstance from '../../utils/axiosInstance';

const BatteryList = () => {
    const [batteryData, setBatteryData] = useState([]);

    // Function to fetch battery data from backend
    const fetchBatteryData = async () => {
        try {
            // Mendapatkan waktu saat ini
            const now = new Date();
            const hours = now.getHours(); // Jam saat ini
            const minutes = now.getMinutes(); // Menit saat ini
            const currentTime = `${hours}:${minutes}`; // Format waktu menjadi HH:mm

            // Mengambil data dari backend dengan waktu saat ini
            const response = await axiosInstance.get(`/battery?time=${currentTime}`);
            setBatteryData(response.data.batteryData); // Ambil data battery dari response
            // console.log(batteryData[0]?.total.voltage)
        } catch (error) {
            console.error("Error fetching battery data:", error);
        }
    };

    // useEffect to fetch data every 10 seconds
    useEffect(() => {
        fetchBatteryData(); // Initial data load
        const interval = setInterval(() => {
            fetchBatteryData(); // Update every 10 seconds
        }, 10000);

        return () => clearInterval(interval); // Clear interval on component unmount
    }, []);

    // Membagi data battery menjadi dua bagian: pertama (1-20) dan kedua (21-40)
    const firstHalfCells = batteryData.length > 0 ? Object.keys(batteryData[0].cells).slice(0, 20) : [];
    const secondHalfCells = batteryData.length > 0 ? Object.keys(batteryData[0].cells).slice(20, 40) : [];

    // Fungsi untuk membuat baris data untuk setiap bagian
    const renderCellData = (cells) => {
        return cells.map((cell, index) => (
            <td key={index} className="text-center font-semibold py-5">
                {batteryData[0].cells[cell] ? batteryData[0].cells[cell].voltage : '-'}
            </td>
        ));
    };

    return (
        <BatteryBase title={"Battery Monitoring System"} totalVoltage={batteryData[0]?.total.voltage} currentAmpere={batteryData[0]?.total.current} >
            {/* Tabel pertama: Sel 1-20 */}
            <div className="overflow-x-auto mb-6">
                <table className="w-full mb-4" border="1">
                    <tbody>
                        <tr>
                            <td rowSpan="2"></td>
                            {firstHalfCells.map((cell, index) => (
                                <td key={index} className="font-semibold text-center">{cell}</td>
                            ))}
                        </tr>
                        <tr>
                            {firstHalfCells.map((cell, index) => (
                                <td key={index} className="text-center">
                                    <img src={BatteryIcon} alt={`Battery Cell ${cell}`} />
                                </td>
                            ))}
                        </tr>
                        <tr className="bg-neutron-primary/10 hover:bg-neutron-primary/15">
                            <td className="text-center">Voltage (Vdc)</td>
                            {renderCellData(firstHalfCells)}
                        </tr>
                        <tr className="hover:bg-neutron-primary/15">
                            <td className="text-center">Int Res (uΩ)</td>
                            {firstHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.internal_resistance}</td>
                            ))}
                        </tr>
                        <tr className="bg-neutron-primary/10 hover:bg-neutron-primary/15">
                            <td className="text-center">Temp (C)</td>
                            {firstHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.internal_temperature}</td>
                            ))}
                        </tr>
                        <tr className="hover:bg-neutron-primary/15">
                            <td className="text-center">SOC (%)</td>
                            {firstHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.state_of_charge}</td>
                            ))}
                        </tr>
                        <tr className="bg-neutron-primary/10 hover:bg-neutron-primary/15">
                            <td className="text-center">SOH (%)</td>
                            {firstHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.state_of_health}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Tabel kedua: Sel 21-40 */}
            <div className="overflow-x-auto">
                <table className="w-full mb-4" border="1">
                    <tbody>
                        <tr>
                            <td rowSpan="2"></td>
                            {secondHalfCells.map((cell, index) => (
                                <td key={index} className="font-semibold text-center">{cell}</td>
                            ))}
                        </tr>
                        <tr>
                            {secondHalfCells.map((cell, index) => (
                                <td key={index} className="text-center">
                                    <img src={BatteryIcon} alt={`Battery Cell ${cell}`} />
                                </td>
                            ))}
                        </tr>
                        <tr className="bg-neutron-primary/10 hover:bg-neutron-primary/15">
                            <td className="text-center">Voltage (Vdc)</td>
                            {renderCellData(secondHalfCells)}
                        </tr>
                        <tr className="hover:bg-neutron-primary/15">
                            <td className="text-center">Int Res (uΩ)</td>
                            {secondHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.internal_resistance}</td>
                            ))}
                        </tr>
                        <tr className="bg-neutron-primary/10 hover:bg-neutron-primary/15">
                            <td className="text-center">Temp (C)</td>
                            {secondHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.internal_temperature}</td>
                            ))}
                        </tr>
                        <tr className="hover:bg-neutron-primary/15">
                            <td className="text-center">SOC (%)</td>
                            {secondHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.state_of_charge}</td>
                            ))}
                        </tr>
                        <tr className="bg-neutron-primary/10 hover:bg-neutron-primary/15">
                            <td className="text-center">SOH (%)</td>
                            {secondHalfCells.map((cell, index) => (
                                <td key={index} className="text-center font-semibold py-5">{batteryData[0].cells[cell]?.state_of_health}</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Navigasi Halaman */}
            <div className="flex items-center justify-between mt-6">
                <button className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                    </svg>
                    <span>
                        previous
                    </span>
                </button>
                <div className="items-center hidden md:flex gap-x-3">
                    <div className="text-neutron-primary text-xl text-bold">Page 1/2</div>
                </div>
                <button className="flex items-center px-5 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                    <span>
                        Next
                    </span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>
                </button>
            </div>
        </BatteryBase>
    );
};

export default BatteryList;
