import React from 'react';
import bglogin from '../assets/images/thinger_io_platform.png'

const Auth = ({ children }) => {
  return (
    <div className="flex flex-col md:flex-row h-screen">
      <div className="hidden md:flex items-center justify-center relative bg-gray-100">
        <div className="mx-auto text-center">
          <img src={bglogin} alt="Neutron Logo" className="w-3/4 h-auto mx-auto" />
          <a href="https://www.iotneutron.com" className="text-neutron-primary/80 hover:text-neutron-primary text-space-grotesk text-xl">iotneutron.com</a>
        </div>
      </div>
      <div className="w-full md:w-1/3 bg-white flex items-center justify-center h-full">
        <div className="w-full mx-5 md:mx-14">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Auth;
