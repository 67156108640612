import React, { Component } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';
import Overlay from 'ol/Overlay';

class MapHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zoomLevel: 5, // Default zoom level
            points: [
                {
                    name: 'Jakarta',
                    coord: fromLonLat([106.84513, -6.21462]),
                    info: 'Jakarta, the capital city of Indonesia.',
                },
                {
                    name: 'Bandung (Dago)',
                    coord: fromLonLat([107.6191, -6.8903]),
                    info: 'Bandung, located in Dago area.',
                },
                {
                    name: 'Bandung (Majalaya)',
                    coord: fromLonLat([107.7556, -7.0127]),
                    info: 'Bandung, located in Majalaya.',
                    zoomRequired: 8, // Appears at zoom level 8
                },
                {
                    name: 'Banyumas',
                    coord: fromLonLat([109.2301, -7.5152]),
                    info: 'Banyumas, Central Java.',
                },
                {
                    name: 'Kebumen',
                    coord: fromLonLat([109.6525, -7.6688]),
                    info: 'Kebumen, Central Java.',
                },
            ],
        };
        this.map = null;
        this.popup = null;
    }

    componentDidMount() {
        if (!this.map) {
            this.initMap(); // Inisialisasi map hanya jika belum ada
        }
    }

    componentWillUnmount() {
        if (this.map) {
            // Bersihkan overlay dan listener saat komponen di-unmount
            this.map.setTarget(null);
            this.map = null;
        }
    }

    initMap() {
        // Create the OpenLayers Map
        this.map = new Map({
            target: 'map', // Pastikan hanya ada satu elemen dengan ID ini
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: fromLonLat([107.6191, -6.8903]), // Bandung as initial center
                zoom: this.state.zoomLevel,
            }),
        });

        // Create a Vector Source and Layer
        this.vectorSource = new VectorSource();
        this.vectorLayer = new VectorLayer({
            source: this.vectorSource,
        });
        this.map.addLayer(this.vectorLayer);

        // Add markers based on the initial zoom level
        this.addMarkers();

        // Set up zoom event listener
        this.map.getView().on('change:resolution', () => {
            this.setState({ zoomLevel: this.map.getView().getZoom() }, () => {
                this.updateMarkers();
            });
        });

        // Popup for showing information
        const popupElement = document.getElementById('popup');
        if (popupElement) {
            this.popup = new Overlay({
                element: popupElement,
                positioning: 'bottom-center',
                stopEvent: false,
                offset: [0, -10],
            });
            this.map.addOverlay(this.popup);
        } else {
            console.error('Popup element not found.');
        }

        // Click event to show popup
        this.map.on('click', (event) => {
            this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
                const coordinates = feature.getGeometry().getCoordinates();
                const info = feature.get('info');
                this.showPopup(coordinates, info);
            });
        });
    }

    addMarkers() {
        const { points, zoomLevel } = this.state;

        points.forEach((point) => {
            // Check if point should be visible based on zoom level
            if (!point.zoomRequired || point.zoomRequired <= zoomLevel) {
                const feature = new Feature({
                    geometry: new Point(point.coord),
                    name: point.name,
                    info: point.info,
                });

                // Set marker icon
                feature.setStyle(
                    new Style({
                        image: new Icon({
                            anchor: [0.5, 1],
                            src: 'https://openlayers.org/en/v6.5.0/examples/data/icon.png',
                        }),
                    })
                );

                this.vectorSource.addFeature(feature);
            }
        });
    }

    updateMarkers() {
        this.vectorSource.clear(); // Clear all markers first
        this.addMarkers(); // Re-add markers based on the new zoom level
    }

    showPopup(coordinates, info) {
        const popupContentElement = document.getElementById('popup-content');
        
        if (popupContentElement) {
            popupContentElement.innerHTML = info;  // Set konten popup
            this.popup.setPosition(coordinates);  // Tampilkan popup di lokasi yang sesuai
        } else {
            console.error('Popup content element not found.');
        }
    }

    render() {
        return (
            <div>
                <div id="map" style={{ width: '100%', height: '400px' }}></div>
                {/* Popup HTML */}
                <div id="popup" className="ol-popup">
                    <div id="popup-content"></div>
                </div>
                <style jsx>{`
                    .ol-popup {
                        position: absolute;
                        background-color: white;
                        padding: 10px;
                        border: 1px solid black;
                        border-radius: 10px;
                        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
                        z-index: 1000;
                    }
                `}</style>
            </div>
        );
    }
}

export default MapHome;
