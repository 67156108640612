import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiPowerOff } from "react-icons/bi";

const TopMenu = () => {
    const [greeting, setGreeting] = useState("");
    const [dateTime, setDateTime] = useState("Loading Time...");
    const getUserAuth = JSON.parse(localStorage.getItem('userData')) || {}
    const navigate = useNavigate();

    const handleLogout = () => {
        // Hapus data dari localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userData');
        localStorage.removeItem('userMenus');

        // Redirect ke halaman login
        navigate('/login');
    };

    // Function to update the greeting based on the current time
    const updateGreeting = () => {
        const now = new Date();
        const hours = now.getHours();
        let greetingMessage;

        if (hours < 12) {
            greetingMessage = "Good morning";
        } else if (hours < 18) {
            greetingMessage = "Good afternoon";
        } else {
            greetingMessage = "Good evening";
        }

        setGreeting(greetingMessage);
    };

    // Function to update the current date and time every second
    const updateDateTime = () => {
        const now = new Date();

        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const year = now.getFullYear();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const formattedDateTime = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
        setDateTime(formattedDateTime);
    };

    // useEffect to run when the component mounts
    useEffect(() => {
        updateGreeting(); // Set initial greeting
        const intervalId = setInterval(updateDateTime, 1000); // Update time every second

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    return (
        <div className="relative z-[51] flex h-[67px] items-center border-b border-slate-200">
            <h2 className="hidden md:flex text-md mr-auto text-neutron-primary">
                <span>{greeting},</span>
            </h2>
            <div className="relative w-full md:w-fit">
                <div id="datetime" className="text-md flex  w-full justify-between items-center text-neutron-primary">
                    <div>
                        {dateTime},
                        <span className="ml-1">{getUserAuth.name}</span>
                    </div>
                    <span className="ml-2">
                        <button onClick={handleLogout} className="bg-red-700 text-white p-1 rounded-lg transition ease-in-out delay-150 hover:bg-red-900"><BiPowerOff className="text-xl" /></button>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TopMenu;
