import React, { useState, useEffect, Suspense, lazy } from "react";
import Base from "../../layouts/Base";

// Lazy load VectorComponent
const VectorComponent = lazy(() => import("./Vector"));

const Powersystem = () => {
    return (
        <Base>
            <div className={`animate-slide-in-up px-2 bg-white rounded-3xl shadow-lg`}>
                <Suspense fallback={<div>Loading...</div>}>
                    <iframe
                        src="https://backenddemo.iotneutron.com/powesystem-rendering"
                        title="Power System Rendering"
                        style={{ width: '90%', height: '95vh', border: 'none', margin: 'auto' }}
                    />
                </Suspense>
            </div>
        </Base>
    );
}

export default Powersystem;