import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Button component
const Button = ({
    size = "medium",
    variant = "blue",
    children,
    icon,
    iconPosition = "left",
    fullWidth = false,  // Default false
    ...props
}) => {
    const baseStyles = "text-white font-medium rounded-lg focus:outline-none focus:ring-4";

    // Size styles
    const sizeStyles = {
        small: "text-sm px-3 py-2.5",
        medium: "text-sm px-5 py-2.5",
        large: "text-lg px-6 py-3",
    };

    // Color/variant styles
    const variantStyles = {
        blue: "bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-blue-300 dark:focus:ring-blue-800",
        green: "bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-green-300 dark:focus:ring-green-800",
        cyan: "bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-cyan-300 dark:focus:ring-cyan-800",
        teal: "bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-teal-300 dark:focus:ring-teal-800",
        lime: "text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-lime-300 dark:focus:ring-lime-800",
        red: "bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-red-300 dark:focus:ring-red-800",
        pink: "bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-pink-300 dark:focus:ring-pink-800",
        purple: "bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-purple-300 dark:focus:ring-purple-800",
    };

    // Conditional classes for icon position
    const iconPositionStyles = icon && iconPosition === "left" ? "flex flex-row-reverse items-center" : "flex items-center";

    // Full width conditional class
    const fullWidthClass = fullWidth ? "w-full" : "";

    return (
        <button
            type="button"
            className={clsx(
                baseStyles,
                sizeStyles[size],
                variantStyles[variant],
                "me-2 mb-2", // margin
                iconPositionStyles,
                fullWidthClass, // Add w-full class if fullWidth is true
                "flex justify-center items-center" // Center the text and icon
            )}
            {...props}
        >
            {icon && <span className={clsx(iconPosition === "left" ? "ml-2" : "mr-2") }>{icon}</span>}
            {children}
        </button>
    );
};

// Prop types for the component
Button.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    variant: PropTypes.oneOf([
        "blue",
        "green",
        "cyan",
        "teal",
        "lime",
        "red",
        "pink",
        "purple",
    ]),
    children: PropTypes.node.isRequired,
    icon: PropTypes.node,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    fullWidth: PropTypes.bool,  // Define fullWidth prop
};

export default Button;
