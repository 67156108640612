import React from 'react';
import MobileMenu from './partials/MobileMenu';
import SideMenu from './partials/SideMenu';
import TopMenu from './partials/TopMenu';


const Base = ({ children }) => {
    return (
        <div className="flex">
            <div className="h-screen bg-slate-100 w-5 hidden xl:block" />
            <div className="w-full rubick md:pr-5 sm:pr-4 sm:pl-4 md:pl-0 py-5 bg-gradient-to-b from-neutron-primary to-gray-500 before:fixed before:inset-0 before:z-[-1]">
                {/* BEGIN: Mobile Menu */}
                <MobileMenu />
                {/* END: Mobile Menu */}
                <div className="mt-[4.7rem] flex md:mt-0">
                    {/* BEGIN: Side Menu */}
                    <SideMenu />
                    <div className="md:max-w-auto min-h-screen min-w-0 max-w-full flex-1 rounded-[30px] bg-slate-100 px-4 pb-10 before:block before:h-px before:w-full before:content-[''] dark:bg-darkmode-700 md:px-[22px]">
                        <TopMenu />
                        {/* END: Top Bar */}
                        <main>
                            {children}
                        </main>
                    </div>
                    {/* END: Content */}
                </div>
            </div>
        </div>
    );
};

export default Base;
