import axiosInstance from '../utils/axiosInstance';

export const login = async (emailOrUsername, password) => {
    try {
        const response = await axiosInstance.post('/auth/login', {
            emailOrUsername,
            password,
        });

        const { accessToken, refreshToken, user } = response.data;

        // Simpan ke localStorage
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userData', JSON.stringify({
            id: user.id,
            email: user.email,
            name: user.name,
            username: user.username
        }));
        localStorage.setItem('userMenus', JSON.stringify(user.menus));

        return response.data;
    } catch (error) {
        throw error.response?.data?.error || 'An error occurred';
    }
};
