import React from "react";
import Base from "../../layouts/Base";

const RealtimeMonitoringExternal = () => {
    return (
        <Base>
            <div className="my-3">
                <h2 className="animate-fade-in text-xl uppercase font-semibold">
                    Realtime Monitoring External
                </h2>
            </div>
            <div className={`animate-slide-in-up p-4 bg-white rounded-3xl shadow-lg my-5`}>
                External
            </div>
        </Base>
    );
}

export default RealtimeMonitoringExternal;