import React, { useEffect, useState } from "react";
import CleanLayout from "../../layouts/CleanLayout";
import axiosInstance from "../../utils/axiosInstance";
import Swal from "sweetalert2";
import { IoFingerPrint } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const Verification = () => {
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const url = window.location.href;
        const params = new URLSearchParams(url.split('?')[1]);
        const tokenFromUrl = params.get('token');
        const emailFromUrl = params.get('email');

        if (tokenFromUrl) setToken(tokenFromUrl);
        if (emailFromUrl) setEmail(emailFromUrl);
    }, []);

    const handleVerifyProcess = async () => {
        Swal.fire({
            title: 'Be patient...',
            text: 'Please wait while we process your verification.',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const response = await axiosInstance.get(`/auth/verification_token?token=${token}&email=${email}`);
            Swal.close();

            if (response.status === 200) {
                // Jika verifikasi berhasil
                Swal.fire({
                    title: 'Verification Successful!',
                    text: 'Congratulations, your account has been successfully verified.',
                    icon: 'success',
                    allowOutsideClick: false,
                    confirmButtonColor: '#009e4d',
                    confirmButtonText: 'Go to Login',
                }).then(() => {
                    navigate('/login');
                });
            }
        } catch (error) {
            Swal.close();
            const errorMessage = error.response?.data?.error || 'An error occurred. Please try again.';
            Swal.fire({
                title: 'Verification Failed!',
                text: errorMessage,
                icon: 'error',
                allowOutsideClick: false,
                confirmButtonColor: '#009e4d',
                confirmButtonText: 'Try Again',
            }).then(() => {
                navigate('/login');
            });
        }
    };

    useEffect(() => {
        if (token && email) {
            handleVerifyProcess();
        }
    }, [token, email]);

    return (
        <CleanLayout>
            <div className="text-center bg-white p-8 rounded-lg shadow-md">
                <div className="text-green-500 text-6xl mb-4 text-center">
                    <IoFingerPrint className="fas fa-check-circle mx-auto" />
                </div>
                <h1 className="text-2xl font-bold mb-2">Verification in Progress...</h1>
                <p className="text-gray-600 mb-6">
                    Please wait while we verify your account.
                </p>
            </div>
        </CleanLayout>
    );
};

export default Verification;
