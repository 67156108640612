import React from 'react';
import NoInternetGif from '../../assets/images/nointernet.gif';

function NoInternet() {

    return (
        <div className="bg-white h-screen flex items-center justify-center">
            <div className="">
                <img src={NoInternetGif} className="mx-auto" alt="No Internet Gif" />
                <div className="text-center mt-5">
                    <h1 className="md:text-2xl text-osda-primary/50 uppercase mt-3 mb-1">
                        no Internet connection
                    </h1>
                    <span className="text-osda-primary/60 md:text-xl">
                        Please check your internet connection...
                    </span>
                </div>
            </div>
        </div>
        
    );
}

export default NoInternet;

