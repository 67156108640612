import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import Base from '../../layouts/Base';
// import Swal from 'sweetalert2';
import StatsHome from './Stats';
import ChartHome from './Chart';
import MapHome from './Map';
// import ErrorMessage from '../../components/ErrorMessage';

const Home = () => {
    // const [vessels, setVessels] = useState([]);
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(null);
    // const [onlineStatus, setOnlineStatus] = useState({});
    // const [selectedVessel, setSelectedVessel] = useState(null);
    // const navigate = useNavigate();

    return (
        <Base>
            {/* {loading ? (
                <div className="flex justify-center">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-gray-600" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ) : error ? (
                <ErrorMessage message={error} />
            ) : (
                <div>
                    <div className={`animate-slide-in-up my-10`}>
                        <StatsHome />
                    </div>
                    <div className={`animate-slide-in-up p-4 bg-white rounded-md shadow-md my-5`}>
                        <ChartHome />
                    </div>
                    <div className={`animate-slide-in-up p-4 bg-white rounded-md shadow-md my-5`}>
                        <MapHome />
                    </div>
                </div>
            )} */}
            <div>
                <div className={`animate-slide-in-up my-10`}>
                    <StatsHome />
                </div>
                <div className={`animate-slide-in-up p-4 bg-white rounded-md shadow-md my-5`}>
                    <ChartHome />
                </div>
                <div className={`animate-slide-in-up p-4 bg-white rounded-md shadow-md my-5`}>
                    <MapHome />
                </div>
            </div>
        </Base>
    );
};

export default Home;
