import React from "react";
import BatteryBase from "./BatteryBase";

const BatteryChart = () => {
    return (
        <BatteryBase title={"Battery Chart"}>
            <div>
                Halso
            </div>
        </BatteryBase>
    );
}

export default BatteryChart;
