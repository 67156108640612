import React from "react";
import Base from "../../layouts/Base";
import VectorFuelSystem from "../../components/FuelSystem";

const FuelSystem = () => {
    return (
        <Base>
            <div className="my-3">
                <h2 className="animate-fade-in text-xl uppercase font-semibold">Fuel System</h2>
            </div>
            <div className={`animate-slide-in-up p-4 bg-gray-300 rounded-md shadow-md my-5`}>
                <VectorFuelSystem className="w-full h-auto" />
            </div>
        </Base>
    );
}

export default FuelSystem;