import React from "react";
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo-neutron.webp';
import {
    TbHome,
    TbSolarElectricity,
    TbBatteryAutomotive,
    TbGasStation,
    TbCheckupList
} from "react-icons/tb";
import { MdQueryStats } from "react-icons/md";
import { ImStatsDots } from "react-icons/im";


const SideMenu = () => {
    const location = useLocation();

    return (
        <nav className="side-nav hidden w-[80px] overflow-x-hidden pb-16 pr-5 md:block xl:w-[230px]">
            <Link to={`/`} className="flex items-center pt-4 pl-5 intro-x">
                <img className="w-full h-auto" src={Logo} alt="Neutron Logo" />
            </Link>
            <div className="my-6 side-nav__divider" />
            <ul>
                <li>
                    <Link
                        to={`/`}
                        className={`side-menu z-20 ${location.pathname === '/' ? 'side-menu--active text-neutron-primary' : 'text-white'}`}
                    >
                        <div className="side-menu__icon">
                            <TbHome className="stroke-1.5 w-6 h-6" />
                        </div>
                        <div className="side-menu__title">
                            Home
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        to={`/powersystem`}
                        className={`side-menu  z-20 ${location.pathname === '/powersystem' ? 'side-menu--active text-neutron-primary' : 'text-white'}`}
                    >
                        <div className="side-menu__icon">
                            <TbSolarElectricity className="stroke-1.5 w-6 h-6" />
                        </div>
                        <div className="side-menu__title">
                            Power System
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        to={`/batterysystem`}
                        className={`side-menu z-20 ${location.pathname.startsWith('/batterysystem') ? 'side-menu--active text-neutron-primary' : 'text-white'}`}
                    >
                        <div className="side-menu__icon">
                            <TbBatteryAutomotive className="stroke-1.5 w-6 h-6" />
                        </div>
                        <div className="side-menu__title">
                            Battery System
                        </div>
                    </Link>
                </li>

                <li>
                    <Link
                        to={`/fuelsystem`}
                        className={`side-menu  z-20 ${location.pathname === '/fuelsystem' ? 'side-menu--active text-neutron-primary' : 'text-white'}`}
                    >
                        <div className="side-menu__icon">
                            <TbGasStation className="stroke-1.5 w-6 h-6" />
                        </div>
                        <div className="side-menu__title">
                            Fuel System
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        to={`/eventalarm`}
                        className={`side-menu  z-20 ${location.pathname === '/eventalarm' ? 'side-menu--active text-neutron-primary' : 'text-white'}`}
                    >
                        <div className="side-menu__icon">
                            <TbCheckupList className="stroke-1.5 w-6 h-6" />
                        </div>
                        <div className="side-menu__title">
                            Event Alarm
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        to={`/realtimeinternal`}
                        className={`side-menu  z-20 ${location.pathname === '/realtimeinternal' ? 'side-menu--active text-neutron-primary' : 'text-white'}`}
                    >
                        <div className="side-menu__icon">
                            <MdQueryStats className="stroke-1.5 w-6 h-6" />
                        </div>
                        <div className="side-menu__title">
                            Realtime Internal
                        </div>
                    </Link>
                </li>
                <li>
                    <Link
                        to={`/realtimeexternal`}
                        className={`side-menu  z-20 ${location.pathname === '/realtimeexternal' ? 'side-menu--active text-neutron-primary' : 'text-white'}`}
                    >
                        <div className="side-menu__icon">
                            <ImStatsDots className="stroke-1.5 w-6 h-6" />
                        </div>
                        <div className="side-menu__title">
                            Realtime External
                        </div>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default SideMenu;
