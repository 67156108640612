import React from "react";
import Base from "../../layouts/Base";
import TableEventAlarm from "./Tables";


const EventAlarm = () => {
    return (
        <Base>
            <div className="my-3">
                <h2 className="animate-fade-in text-xl uppercase font-semibold">
                    Event Alarm
                </h2>
            </div>
            <div className="bg-white rounded-lg shadow-md p-2">
                <TableEventAlarm />
            </div>
        </Base>
    );
}

export default EventAlarm;