import React from 'react';
import NotFoundIcon from '../../assets/images/404.gif';
import Button from '../../components/Button';
import { TbHome } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const handleClickGoHome = () => {
        // console.log("Navigating to home...");
        navigate('/');
    };

    return (
        <div
            className="relative flex items-center justify-center h-screen sm:items-center sm:pt-0"
            style={{ background: '#f7f9fb' }}
        >
            <div className="max-w-xl text-center mx-auto px-6 lg:px-8">
                <img alt="404 Icon" className="mx-auto mb-2 md:mb-10 w-28 md:w-40 h-28 md:h-40" src={NotFoundIcon} />
                <div className="flex items-center text-center pt-8 justify-center sm:pt-0">
                    <div className="px-4 text-4xl text-osda-primary border-r border-gray-400 tracking-wider">
                        404
                    </div>
                    <div className="ml-4 text-4xl text-osda-primary uppercase tracking-wider">
                        Not Found
                    </div>
                </div>
                <div className="text-center text-sm md:text- text-gray-500 mt-5">
                    Oops! The page you're looking for is not available. It may have been moved or deleted.
                </div>
                <div className="text-center text-sm text-gray-500 mt-5">
                    <Button onClick={handleClickGoHome} size="medium" variant="green" fullWidth={true} icon={<TbHome />} iconPosition="left">
                        Back To Home
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
