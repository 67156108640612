import React, { useState } from 'react';
import Auth from '../../layouts/Auth';
import { Link, useNavigate } from 'react-router-dom';
import { TbUserScan, TbSend, TbMailbox, TbPhone } from "react-icons/tb";
import logo from '../../assets/images/favicon.webp';
import axiosInstance from '../../utils/axiosInstance';
import Swal from 'sweetalert2';

const Register = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const handleRegister = async (e) => {
        e.preventDefault(); // Prevent default form submission
        Swal.fire({
            title: 'Logging in...',
            text: 'Please wait while we process your registration.',
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const response = await axiosInstance.post('/auth/register-sendmail', {
                name,
                email,
                phone
            });
            Swal.close();
            Swal.fire({
                title: 'Success!',
                text: 'Please check your email to verify your account.',
                icon: 'success',
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 2000,
            }).then(() => {
                navigate('/login');
            });
        } catch (err) {
            Swal.close();
            Swal.fire({
                title: 'Register Failed',
                text: err.response?.data?.error || 'An error occurred during registration.',
                icon: 'error',
                confirmButtonColor: '#025db7',
                confirmButtonText: 'Try Again'
            });
        }
    };

    return (
        <Auth>
            <div className="mx-auto text-center mb-5">
                <img src={logo} alt="Neutron Logo" className="mx-auto mb-3" />
                <h2 className="text-xl text-neutron-primary md:text-2xl font-extrabold text-space-grotesk">
                    Welcome to Scada by Neutron
                </h2>
            </div>
            <div className="w-full flex-1 mt-8">
                <form onSubmit={handleRegister} className="mx-auto w-full">
                    <div className="my-3">
                        <label htmlFor="fullname" className="mb-2 block text-sm font-medium">Fullname</label>
                        <div className="relative">
                            <input
                                type="text"
                                id="fullname"
                                name="fullname"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="block w-full rounded-md border border-gray-200 py-3 px-4 pr-11 text-sm shadow-sm outline-none focus:z-10 focus:border-osda-primary focus:ring-osda-primary"
                                placeholder="Fullname"
                                required
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 z-20 flex items-center pr-4">
                                <TbUserScan className="h-6 w-6 text-gray-400" />
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                        <label htmlFor="email" className="mb-2 block text-sm font-medium">Email</label>
                        <div className="relative">
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full rounded-md border border-gray-200 py-3 px-4 pr-11 text-sm shadow-sm outline-none focus:z-10 focus:border-osda-primary focus:ring-osda-primary"
                                placeholder="Email"
                                required
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 z-20 flex items-center pr-4">
                                <TbMailbox className="h-6 w-6 text-gray-400" />
                            </div>
                        </div>
                    </div>

                    <div className="my-3">
                        <label htmlFor="phone" className="mb-2 block text-sm font-medium">Phone</label>
                        <div className="relative">
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="block w-full rounded-md border border-gray-200 py-3 px-4 pr-11 text-sm shadow-sm outline-none focus:z-10 focus:border-osda-primary focus:ring-osda-primary"
                                placeholder="Phone"
                                required
                            />
                            <div className="pointer-events-none absolute inset-y-0 right-0 z-20 flex items-center pr-4">
                                <TbPhone className="h-6 w-6 text-gray-400" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <span className="text-gray-600 text-xs">
                            Use an active email and phone number for verification. Thank you!
                        </span>
                    </div>
                    <button
                        type="submit" // Specify type as submit
                        className="mt-5 tracking-wide font-semibold bg-neutron-primary/90 text-gray-100 w-full py-4 rounded-lg hover:bg-osda-primary transition-all duration-300 ease-in-out flex items-center justify-center">
                        <span className="mr-5">Register</span>
                        <TbSend className="w-6 h-6" />
                    </button>

                    <p className="mt-6 text-xs text-gray-600 text-center w-full">
                        Please visit the
                        <Link to={`/login`} className="border-b border-gray-500 border-dotted mx-2 font-semibold">
                            Login page
                        </Link>
                        if you already have an account.
                    </p>
                </form>
            </div>
        </Auth>
    );
};

export default Register;
