import React, { useState } from 'react';
import Logo from '../../assets/images/logo-neutron.webp';
import {
    TbHome,
    TbSolarElectricity,
    TbBatteryAutomotive,
    TbGasStation,
    TbCheckupList
} from "react-icons/tb";
import { Link, useLocation } from 'react-router-dom';

const MobileMenu = () => {

    const location = useLocation();
    // State untuk melacak apakah menu mobile sedang aktif atau tidak
    const [isMenuActive, setIsMenuActive] = useState(false);

    // Fungsi untuk toggle (buka/tutup) menu
    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    return (
        <div className={`mobile-menu group top-0 inset-x-0 fixed bg-theme-1/90 z-[60] border-b border-white/[0.08] dark:bg-darkmode-800/90 md:hidden`}>
            <div className="flex h-[70px] items-center px-3 sm:px-8 z-30">
                <div className="mr-auto flex">
                    <img className="w-36 h-auto" src={Logo} alt="Neutron Logo" />
                </div>
                <div className="mobile-menu-toggler text-white" onClick={toggleMenu}>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                        />
                    </svg>
                </div>

            </div>

            {/* Overlay hitam */}
            <div className={`fixed inset-0 z-20 bg-black/90 transition-opacity duration-300 ease-in-out ${isMenuActive ? 'opacity-100 visible' : 'opacity-0 invisible'}`} onClick={toggleMenu}></div>

            {/* Menu Scrollable */}
            <div className={`scrollable fixed top-0 left-0 z-30 h-screen w-[270px] bg-primary dark:bg-darkmode-800 transition-transform duration-300 ease-in-out ${isMenuActive ? 'translate-x-0' : '-translate-x-full'}`}>
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                    <ul className="space-y-2 font-medium">
                    <li>
                            <Link
                                to={`/`}
                                className={`flex items-center p-2 text-gray-900 rounded-lg  group  ${location.pathname === '/' ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                            >
                                <TbHome className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                
                                <span className="ms-3">Home</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/powersystem`}
                                className={`flex items-center p-2 text-gray-900 rounded-lg  group  ${location.pathname === '/powersystem' ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                            >
                                <TbSolarElectricity className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                
                                <span className="ms-3">Power System</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/batterysystem`}
                                className={`flex items-center p-2 text-gray-900 rounded-lg  group  ${location.pathname === '/batterysystem' ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                            >
                                <TbBatteryAutomotive className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                
                                <span className="ms-3">Battery System</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/fuelsystem`}
                                className={`flex items-center p-2 text-gray-900 rounded-lg  group  ${location.pathname === '/fuelsystem' ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                            >
                                <TbGasStation className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                
                                <span className="ms-3">Fuel System</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to={`/eventalarm`}
                                className={`flex items-center p-2 text-gray-900 rounded-lg  group  ${location.pathname === '/eventalarm' ? 'bg-gray-200' : 'hover:bg-gray-200'}`}
                            >
                                <TbCheckupList className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" />
                                
                                <span className="ms-3">Event Alarm</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
